import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { Container as ContainerBase } from "components/misc/Layouts.js"
// import logo from "../../images/logo.png"
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg"
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg"
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg"
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg"

const Container = tw(ContainerBase)`bg-gray-200 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center`
const LogoImg = tw.img`w-1/2 sm:w-1/4`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`
const Phone = tw.a`text-center mt-3 tracking-wide text-sm text-gray-600`

const SocialLinksContainer = tw.div`mt-10`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
const CompanyAddress = tw.p`text-center mt-3 tracking-wide text-sm text-gray-600`

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg
              src={"https://cureable.s3.ap-south-1.amazonaws.com/images/logo.png"}
              alt="logo"
            />
          </LogoContainer>
          <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="/about">About</Link>
            <Link href="https://wa.me/919315323513">Contact Us</Link>
            <Link href="/privacy">Privacy Policy</Link>
            <Link href="/terms">Terms</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/cureAble.in">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/cureable_in">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://linkedin.com/company/cureable">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/cureable.in">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, Cureable Home Health Care Pvt Ltd. All Rights Reserved.
          </CopyrightText>
          <CompanyAddress>
            <Link href="https://goo.gl/maps/i19LHnA4mAE42WG6A">
              A-1 Chandra House Complex, Near Batra Cinema, Dr Mukherjee Nagar, G.T.B. Nagar, Delhi
              - 110009
            </Link>
          </CompanyAddress>
          <Phone href="https://wa.me/919315323513">tel: +91-93153-23513</Phone>
        </Row>
      </Content>
    </Container>
  )
}
