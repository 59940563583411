import Form from "react-hubspot-form"
import tw from "twin.macro"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-5 py-20 lg:py-24`

const HubspotForm = ({ toggleModal = () => {} }) => {
  return (
    <Container>
      <Content>
        <div
          tw="mx-auto max-w-4xl"
          style={{
            marginBottom: "1rem",
            fontWeight: "bold",
            fontSize: "1.25rem",
            position: "sticky",
          }}
        >
          <h2>Book a FREE consultation</h2>
        </div>
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          Submit your details here and our team will contact you shortly.
        </div>
        <div>
          <Form
            portalId="44352001"
            formId="23c9af8d-2de4-48fa-b821-0897bae09e8c"
            onSubmit={() => setTimeout(toggleModal, 3000)}
            onReady={(form) => {
              const iframeDoc = form.contentDocument || form.contentWindow.document
              const body = iframeDoc.body
              // get all iframe elements
              // Create a MutationObserver to watch for changes in the iframe's document
              const observer = new MutationObserver((mutationsList, observer) => {
                // Look through all mutations that just occured
                for (let mutation of mutationsList) {
                  // If the addedNodes property has one or more nodes
                  if (mutation.addedNodes.length) {
                    const toDelete = body.getElementsByClassName("hs-form__virality-link")
                    while (toDelete.length > 0) {
                      toDelete[0].parentNode.removeChild(toDelete[0])
                    }
                    // hs-button primary large. give the submit button a style of background-color: #42294A
                    const submitButton = body.getElementsByClassName("hs-button")
                    if (submitButton.length > 0) {
                      submitButton[0].style.backgroundColor = "#42294A"
                    }
                  }
                }
              })

              // Start observing the document with the configured parameters
              observer.observe(body, { childList: true, subtree: true })

              // check if jquery is loaded
              const script = document.createElement("script")
              script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
              let jqueryScript = document.getElementsByTagName("script")
              let src = Array.from(jqueryScript).filter((item) => item.src === script.src)
              if (src.length === 0) {
                document.body.appendChild(script)
              }
            }}
            loading={<div>Loading...</div>}
          />
        </div>
      </Content>
    </Container>
  )
}

export default HubspotForm
