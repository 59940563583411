import React from "react"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import Hero from "components/hero/TwoColumnWithVideo"
import Features from "components/features/ThreeColSimple.js"
import tw from "twin.macro"
import FeatureWithSteps from "components/features/TwoColWithSteps.js"
import Testimonials from "components/testimonials/ThreeColumnWithProfileImage.js"
import FAQ from "components/faqs/SimpleWithSideImage.js"
import Footer from "components/footers/MiniCenteredFooter"

import cureableHero from "images/hero_cureable.png"
// import ParentsFaq from "images/parents-faq.webp"

// import FeaturePlan from "images/features/planning.png"
// import FeatureGuide from "images/features/guide.png"
// import FeatureCare from "images/features/healthcare.png"
// import FeatureGrowth from "images/features/plant.png"
import LearningDisorderImg from "images/conditions/learning-disorder.png"
import SlowLearnersImg from "images/conditions/slow-learning.png"
import AutismImg from "images/conditions/asd.png"
import AdhdImg from "images/conditions/adhd.png"
import DownSyndromeImg from "images/conditions/down-syndrome.png"
import CerebralPalsyImg from "images/conditions/cerebral-palsy.png"
import IntellectualDisabilityImg from "images/conditions/id.png"
import MuscularDystrophyImg from "images/conditions/muscular-dystrophy.png"
import { SectionHeading } from "components/misc/Headings"
import ModalForm from "components/forms/ModalForm"
import { whatsappUrl } from "utils"

// import ScheduleConsult from "images/steps-2/schedule-consult.png"
// import PlanImplement from "images/steps-2/plan-implement.png"
// import CareGrowth from "images/steps-2/nurture-growth.png"

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  // window.gtag("js", new Date())
  // window.gtag("config", "UA-45799926-9")
  const [showModal, setShowModal] = React.useState(false)
  const toggleModal = () => setShowModal(!showModal)

  const Subheading = tw.span`uppercase tracking-widest font-bold text-secondary-500`
  const Heading = tw(SectionHeading)`sm:text-3xl text-2xl`
  const HighlightedText = tw.span`text-primary-500`

  return (
    <AnimationRevealPage disabled>
      <ModalForm modalIsOpen={showModal} toggleModal={toggleModal} />
      <Hero
        heading={
          <>
            <span>Planned & Personalised Therapy for Special kids & Slow Learners.</span>
            <br />
            <HighlightedText>At Home.</HighlightedText>
          </>
        }
        subHeading="For ages 3 to 21"
        description={
          <>
            Expert Therapist nurtures your kid at Home. Following a detailed goal-oriented planner.
            <div>
              With trackable bi-weekly progress reports, we assure your kid's holistic growth.
            </div>
          </>
        }
        imageSrc={cureableHero}
        primaryButtonText="Book a Consultation NOW"
        openModal={toggleModal}
        primaryButtonUrl={whatsappUrl}
        showWatchButton={false}
      />
      <Features
        subheading={
          <Subheading>
            We provide the best <HighlightedText>planned-care</HighlightedText> & nurturing, across
            <HighlightedText> Delhi-NCR</HighlightedText>. All at the comfort of your home.
          </Subheading>
        }
        heading="We cater to mild, moderate & severe cases of:"
        cards={[
          {
            imageSrc: LearningDisorderImg,
            title: "Learning Disorder",
          },
          {
            imageSrc: SlowLearnersImg,
            title: "Slow Learners",
          },
          {
            imageSrc: AutismImg,
            title: "Autism (ASD)",
          },
          {
            imageSrc: AdhdImg,
            title: "ADHD",
          },
          {
            imageSrc: DownSyndromeImg,
            title: "Down Syndrome",
          },
          {
            imageSrc: CerebralPalsyImg,
            title: "Cerebral Palsy",
          },
          {
            imageSrc: IntellectualDisabilityImg,
            title: "Intellectual disability",
          },
          {
            imageSrc: MuscularDystrophyImg,
            title: "Muscular Dystrophy",
          },
        ]}
        linkText={false}
      />
      <Heading>Our 6 steps process for your kid's assured growth</Heading>
      <FeatureWithSteps
        subheading={<Subheading>Start</Subheading>}
        heading={<>Schedule & Consult</>}
        textOnLeft={false}
        imageSrc={"https://cureable.s3.ap-south-1.amazonaws.com/images/steps/schedule-consult.webp"}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={[
          {
            number: 1,
            description:
              "If you're from Delhi-NCR, simply contact us and share some information about your kid.",
          },
          {
            number: 2,
            description:
              "Tell us about your availability, so our team of experts can schedule with you for a consultation.",
          },
        ]}
        primaryButtonText="Book a Consultation NOW"
        primaryButtonUrl={whatsappUrl}
        openModal={toggleModal}
      />
      <FeatureWithSteps
        subheading={<Subheading>Discuss</Subheading>}
        heading={<>Plan & Implement</>}
        textOnLeft={true}
        imageSrc={"https://cureable.s3.ap-south-1.amazonaws.com/images/steps/plan-implement.webp"}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={[
          {
            number: 3,
            description:
              "During consultation, we analyse your kid’s condition providing an in-depth consultation.",
          },
          {
            number: "",
            description:
              "STG (short term goals) & LTG (long term goals) are discussed & noted based on the kid's condition & parent's expectations.",
          },
          {
            number: 4,
            description:
              "On service confirmation, a comprehensive 50-page, 12-weeks-long daily-planner is developed & shared with you.",
          },
          {
            number: "",
            description:
              "Since, we work with an holistic approach. It includes special education (academics), language & behaviour therapy, OT, Sensory Integration, Social & personal grooming, ADL, Pre-vocational.",
          },
        ]}
        primaryButtonText="Book Complete Diagnosis for my Kid"
        primaryButtonUrl={whatsappUrl}
        openModal={toggleModal}
      />
      <FeatureWithSteps
        subheading={<Subheading>Grow</Subheading>}
        heading={<>Nurturing & Growth</>}
        textOnLeft={false}
        imageSrc={"https://cureable.s3.ap-south-1.amazonaws.com/images/steps/care-growth.webp"}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={[
          {
            number: 5,
            description:
              "An experienced & certified special educator is assigned from our team. They start visiting & executing the planner at your premises. Everyday for 1.5 hrs.",
          },
          {
            number: 6,
            description:
              "Every 15-days, we share kid's progress report with parents keeping the process transparent & trackable.",
          },
          {
            number: "",
            description:
              "This systematically personalised, planned & transparent approach brings assured growth in the kid.",
          },
        ]}
        primaryButtonText="Schedule a Consultation NOW"
        primaryButtonUrl={whatsappUrl}
        openModal={toggleModal}
      />
      <Testimonials
        subheading=""
        heading={
          <>
            What <HighlightedText>parents</HighlightedText> are saying about CureAble
          </>
        }
        testimonials={[
          {
            quote:
              "We are incredibly grateful for CureAble and their exceptional home care services. Our child with special needs requires specialized care, and the team has gone above and beyond to meet his unique needs.",
            customerName: "Tanushree",
          },
          {
            quote:
              "CureAble has been a lifeline for our family. Their therapies for our special needs child have made a world of difference in his quality of life. The caregivers are not only highly skilled but also genuinely caring and attentive.",
            customerName: "Naseem Anwar",
          },
          {
            quote:
              "CureAble has been really helpful for our child. Their nurturing for our special needs child have made a remarkable difference in his life.We couldn't be happier with the care and support CureAble has provided.",
            customerName: "Prashant Kumar",
          },
        ]}
      />
      <FAQ
        heading="Get answers to common questions about CureAble"
        description=""
        faqs={[
          {
            question: "How is CureAble better than any day-care centre?",
            answer:
              "CureAble provides all the required services and sessions to the kid at the comfort and safety of your home. We provide intensely personalized care which has been specifically designed and curated for your special kid.",
          },
          {
            question: "What physical and mental conditions are catered by CureAble?",
            answer:
              "Our Care-takers and professionals are capable of catering to the special child of any condition. A customized planner exclusively for your child is designed and then is implemented to improve the life of your special kid.",
          },
          {
            question: "What’s the cost of a consultation?",
            answer:
              "At home consultations are available at a very nominal cost. That too at your own time-slot availability.",
          },
          {
            question: "In what locations CureAble provide their services?",
            answer:
              "We provide our service in the entire Delhi-NCR i.e. Delhi, Noida, Gurgaon, Faridabad, Ghaziabad etc.",
          },
        ]}
        imageSrc={"https://cureable.s3.ap-south-1.amazonaws.com/images/parents-faq.webp"}
        imageShadow={false}
      />
      <Footer />
    </AnimationRevealPage>
  )
}
