import styled from "styled-components"
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js"
import tw from "twin.macro"
import HubspotForm from "./HubspotForm.js"
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg"

const CloseModalButton = tw.button`absolute top-0 right-0 mt-2 mr-2 p-5 hocus:text-primary-500 z-[999]`
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 bg-gray-900 bg-opacity-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-sm absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`

const ModalForm = ({ modalIsOpen, toggleModal }) => {
  return (
    <StyledModal
      closeTimeoutMS={300}
      className="mainHeroModal"
      isOpen={modalIsOpen}
      onRequestClose={toggleModal}
      shouldCloseOnOverlayClick={true}
    >
      <CloseModalButton onClick={toggleModal}>
        <CloseIcon tw="w-6 h-6" />
      </CloseModalButton>
      {/* <SimpleContactUs closeModal={toggleModal}/> */}
      <HubspotForm toggleModal={toggleModal} />
    </StyledModal>
  )
}

export default ModalForm
