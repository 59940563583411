import Icon from "images/whatsapp.svg"
import tw from "twin.macro"
import { whatsappUrl } from "utils"

const Whatsapp = tw.img`w-12 h-12 cursor-pointer z-50 p-2`
const Container = tw.div`fixed bottom-[1.5rem] right-[1.5rem] bg-white p-1 rounded-full shadow-2xl z-50`

const WhatsappFloater = () => {
  return (
    <div>
      <a href={whatsappUrl} target="_blank" rel="noreferrer">
        <Container>
          <Whatsapp src={Icon} alt="whatsapp" />
        </Container>
      </a>
    </div>
  )
}

export default WhatsappFloater
